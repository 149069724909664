
import { MONTH_EN, MONTH_EN_SHORT} from "@/src/constants/constants";
import { DAYOFWEEK_CHOICES } from "../constants/dropdownChoices";
import { calcEndTime } from "./date-time/time";


// export:
// Friday, March 12
// 3月12日（金）
export const displayDate1 = (datetime: Date, locale:'en' | 'jp' = 'jp') : string => {
    // Sunday or 日曜日
    const dow = DAYOFWEEK_CHOICES[datetime.getDay()][locale]
    // 3月 or March
    const monthString = locale=='en' ? MONTH_EN[datetime.getMonth()] : `${datetime.getMonth()+1}月`
    //date
    const date = datetime.getDate()

    // Sunday, April 20
    if (locale=='en') {
        return `${dow}, ${monthString} ${date}`
    }
    // 3月12日（金）
    else {
        return `${monthString}${date}日（${dow}）`
    }
}

// no locale:
// MM/DD/YYYY HH:MM
export const displayDate2 = (date:Date):string => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    
    // // Remove leading zeros from the month, day, and hour
    // const cleanedDate: string = formattedDate.replace(/(^|\/| )0+/g, '$1');
    return formattedDate.replace(',', '');
}


// no locale:
// MM/DD/YYYY
export const displayDate3 = (date:Date):string => {
    const options: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
}

// no locale:
// Friday, March 12, 14:00 - 15:00
// 3月12日（金）14:00 - 15:00
export const displayDate4 = (dateObj:Date, duration:number, locale:'en' | 'jp' = 'jp'):string => {
    const startDate = displayDate1(dateObj, locale)

    // Extract hour and minutes
    const startHour = dateObj.getHours();
    const startMin = dateObj.getMinutes();

    // Pad single-digit hours/minutes with leading zero
    const formattedHours = String(startHour).padStart(2, '0');
    const formattedMinutes = String(startMin).padStart(2, '0');
    const startTime = `${formattedHours}:${formattedMinutes}`;

    const endTime = calcEndTime(startTime, duration)

    return `${startDate} ${startTime}-${endTime}`
}

// no locale:
// YYYY/MM/DD
export const displayDate5 = (date: Date): string => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    
    return `${year}/${month}/${day}`;
}


// no locale:
// MM/DD/YYYY HH:MM
export const displayDate6 = (date:Date):string => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${year}/${month}/${day} ${hours}:${minutes}`;
}


// locale
// Mar 12
// 3月12日
export const displayDateShort1 = (dateObj: Date, locale:'en'|'jp'='jp'):string => {
    const date = dateObj.getDate()
  
    if (locale=='en') {
        const monthString = MONTH_EN_SHORT[dateObj.getMonth()]
        return `${monthString} ${date}`
    } else {
        return `${dateObj.getMonth()+1}月${date}日`
    }
}


// function: displayDayOfWeek
// 
export const displayDayOfWeek = (dateObj: Date, locale:'en'|'jp'='jp') : string => {
    const dow = DAYOFWEEK_CHOICES[dateObj.getDay()][locale]
    return dow
}

// function: displayMonth
// jp: 3月
// en: March
export const displayMonth = (dateObj: Date, locale:'en'|'jp'='jp') : string => {
    //const settings = useAppSelector(state => state.settings).data
    //const locale = settings.language
    
    const month = dateObj.getMonth()+1
  
    if (locale=='en') {
      return `${MONTH_EN[month-1]}`
    } else {
      return `${month}月`
    }
  }




// no locale:
// HH:MM
export const displayTime1 = (date:Date):string => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}


// 14:00 - 16:00
export const displayTimeRange = (start_datetime:Date, end_datetime:Date):string => {
    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };

    const time1 = new Intl.DateTimeFormat('default', options).format(start_datetime);
    const time2 = new Intl.DateTimeFormat('default', options).format(end_datetime);
    return `${time1} - ${time2}`;
}




/**
 * 
 * @param date Datetime object
 * output: 1 week ago, 1 minute ago, etc...
 */
export const calcTimePassed = (date: Date, locale:'en'|'jp'='jp') => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
  
    if (weeks > 0) {
        if (locale=='en') {
            return`${weeks} week${weeks === 1 ? '' : 's'} ago`
        } else {
            return`${weeks}週間前`
        }
    } else if (days > 0) {
        if (locale=='en') {
            return`${days} day${days === 1 ? '' : 's'} ago`
        } else {
            return`${days}日前`
        }
    } else if (hours > 0) {
        if (locale=='en') {
            return`${hours} hour${hours === 1 ? '' : 's'} ago`
        } else {
            return`${hours}時間前`
        }
    } else if (minutes > 0) {
        if (locale=='en') {
            return`${minutes} minute${minutes === 1 ? '' : 's'} ago`
        } else {
            return`${minutes}分前`
        }
    } else {
        if (locale=='en') {
            return`${seconds} second${seconds === 1 ? '' : 's'} ago`
        } else {
            return`${seconds}秒前`
        }
    }
  }




  

export const tomorrow = ():Date => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow

}


export const twoWeeksFromNowDate = () => {
    const twoWeeksFromNow = new Date();
    twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
    return twoWeeksFromNow
}

